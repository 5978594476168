
.custom-styles {
  --ReactInputVerificationCode-itemWidth: 3.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
  --ReactInputVerificationCode-itemSpacing: 2rem;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;  
  font-weight: 380;
}